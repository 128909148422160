.question-creation {
  display: flex;
  margin: 2% 0 0 0;
  align-items: center;
  flex-direction: column;
  width: 80%;

  @include mobile-portrait {
    margin: 25% 0 0 0;
    width: 100%;
  }

  form {
    width: 70%;
  }

  h2 {
    text-align: center;
    color: white;
    margin: 0.5em 0;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 3em;
  }

  h3 {
    text-align: center;
    color: white;
    margin: 0.5em 0;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 1.5em;

    @include mobile-portrait {
      margin: 2em 0;
    }
  }

  label {
    font-size: 2em;
    color: white;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  input, textarea {
    margin: 2em 0 0;

    @include mobile-portrait {
      margin: 2em 0;
    }
  }

  textarea {
    width: 94%;
  }

  button {
    width: 40%;
    margin: 0 auto;
    font-size: 1.4em;
    border: none;
    border-radius: 4px;
    background-color: #357E9D;
    color: white;
    height: 60px;
    transition: 0.3s all;
    display: block;

    @include mobile-portrait {
      margin: 2em auto;
      font-size: 2em;
      width: 80%;
    }

    &:hover {
      cursor: pointer;
      background-color: #41a1ca;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #7d8298;
    }
  }

  &__answers {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include mobile-portrait {
      grid-template-columns: repeat(1, 1fr);
    }

    input {
      font-size: 15px;
      width: 90%;
      padding: 0.5em 0.2em;
    }

    label {
      width: 88%;
      border-radius: 10px;
      margin: 0.7em auto;
      height: 200px;
      display: flex;
      color: white;
      font-weight: 200;
      text-shadow: $text-shadow;
      font-family: "Lato", sans-serif;
      text-align: center;
      padding: 0 2em;
      position: relative;
      z-index: 9;

      @include mobile-portrait {
        width: 100%;
        padding: 0 1em;
      }

      &.active {
        &::after {
          opacity: 1;
          background-color: #357E9D;
        }
      }

      &:hover {
        cursor: pointer;

        &::after {
          opacity: 0.7;
        }
      }

      &:active {
        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: '';
        width: 100%;
        left: 0;
        top: 0;
        background-color: #7d8298;
        opacity: 0.5;
        height: 100%;
        position: absolute;
        border-radius: 10px;
        backdrop-filter: blur(3px);
        z-index: -1;
        transition: all 0.3s;
      }
    }
  }
}
