.auth {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;

  h1 {
    font-size: 2.5em;
    text-align: center;
    font-family: "Lato", sans-serif;
    width: 60%;
    font-weight: 600;
    text-shadow: $text-shadow;
  }
}
