.dashboard-tile {
  width: 30vw;
  background-color: $opaque-bg;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  border: 2px white solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  margin: 0.7em;
  color: white;
  text-decoration: none;
  font-size: 2em;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  transition: all 0.3s;
  text-align: center;

  @include mobile-portrait {
    font-size: 20px;
    width: 45vw;
    margin: 0.3em;
  }

  &:hover {
    background-color: $blue;
  }
}
