.register-button {
  font-family: "Bebas Neue", cursive;
  font-size: 90px;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.7);
  text-decoration: none;
  letter-spacing: 4px;
  color: white;
  background-color: rgba($darkCyan, 0.7);
  backdrop-filter: blur(2px);
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  margin-top: 75px;
  padding: 10px 0;
  width: 30vw;
  text-align: center;
  transition: 0.3s all;

  @include mobile-portrait {
    width: 60vw;
    font-size: 30px;
    margin: 2em auto;
  }

  &:hover {
    background-color: rgba($darkCyan, 0.9);
  }

  &:active {
    background-color: rgba($darkCyan, 1);
  }
}
