.list-of-items {
  min-height: 80vh;
  margin: 10em 0 0 0;
  background-color: $opaque-bg;
  width: 70vw;
  border: 3px solid white;
  border-radius: 10px;

  @include mobile-portrait {
    width: 90vw;
  }

  h2 {
    text-align: center;
    color: white;
    margin: 0.5em 0;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    text-shadow: $text-shadow;
    font-size: 2em;

    @include mobile-portrait {
      font-size: 20px;
    }
  }

  .list-item {
    min-height: 70px;
    width: 80%;
    border-radius: 4px;
    margin: 0.8em auto;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 3em;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

    @include mobile-portrait {
      padding: 0 0.2em;
      width: 95%;
    }

    &--submission {
      &:first-of-type {
        margin: 13vh auto 0.8em auto;
      }

      @include mobile-portrait {
        .list-item__attribute {
          &--email {
            font-size: 11px !important;
          }
        }
      }
    }

    &:nth-of-type(even) {
      background-color: rgba(255, 255, 255, 0.9);
    }

    &__attribute {

      &--index {
        width: 7%;

        @include mobile-portrait {
          width: 10%;
          text-align: center;
        }
      }

      &--admin {
        @include mobile-portrait {
          width: 65% !important;
        }
      }

      &--content {
        width: 63%;

        @include mobile-portrait {
          width: 40%;
        }
      }

      &--email {
        width: 43%;
      }

      &--score {
        width: 10%;
        text-align: center;
      }

      &--attempt-time {
        width: 15%;
        text-align: center;
      }

      &--submitted-at {
        width: 25%;
        text-align: center;
      }

    }

    p {
      font-size: 1.2em;
      font-weight: 700;
      font-family: "Lato", sans-serif;
      color: #333333;

      @include mobile-portrait {
        font-size: 15px;
      }
    }

    .management {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile-portrait {
        width: 35%;
      }

      &__button {
        border: none;
        border-radius: 4px;
        padding: 0.3em 0.6em;
        font-size: 1.2em;
        transition: all 0.3s;
        width: 30%;
        text-align: center;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        margin: 0 0.5em;

        @include mobile-portrait {
          font-size: 11px;
          width: 45%;
        }

        &:hover {
          cursor: pointer;
          filter: brightness(0.8);
        }

        &--delete {
          color: white;
          background-color: rgb(255, 57, 57);
        }

        &--edit {
          text-decoration: none;
          color: white;
          background-color: rgb(82, 170, 252);
        }
      }
    }
  }
}
