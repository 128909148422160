.navbar {
  background-color: $blue;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;

  &__item {
    color: #fff;
    text-decoration: none;
    font-family: "Bebas Neue";
    margin-right: 2rem;
    cursor: pointer;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    transition: 0.3s all;

    @include mobile-portrait {
      font-size: 20px;
      margin-right: 1em;
    }

    &:hover {
      text-decoration: none;
      text-shadow: 2px 2px $shadow-color;
    }

    &:active {
      text-decoration: none;
      text-shadow: 4px 4px $shadow-color;
    }
  }

  &__timer {
    margin: 0 0 0 2rem;
    text-shadow: none;
    cursor: default;
  }
}
