.quiz-wrapper {
  font-family: "Lato", sans-serif;
  color: white;
  text-shadow: $text-shadow;
  background-color: $opaque-bg;
  width: 70vw;
  min-height: 79.5vh;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  margin: 17vh 0 0 0;
  z-index: 99;

  @include mobile-portrait {
    width: 94vw;
    min-height: 67vh !important;
  }

  &__question {
    font-weight: 200;
    font-size: 50px;
    width: 65%;
    text-align: center;
    margin: 1.5em auto 0em;
    min-height: 25vh;

    // make element unselectable
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include mobile-portrait {
      width: 88%;
      margin: 0.5em auto 0;
      min-height: 15vh;
      font-size: 25px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include mobile-portrait {
      width: 95%;
      margin: 1em auto 0;
    }

    .header-element {
      font-weight: 200;
      margin: 0.7em 1.5em;
      font-size: 25px;

      @include mobile-portrait {
        width: 100%;
        margin: 0.3em 0.7em;
        font-size: 20px;
      }

      &--question-number {
        text-align: left;
      }

      &--timer {
        text-align: right;
      }
    }
  }
}
