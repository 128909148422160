.answers-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .answer {
    width: 88%;
    border-radius: 10px;
    margin: 0.7em auto;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 200;
    text-shadow: $text-shadow;
    font-size: 35px;
    font-family: "Lato", sans-serif;
    text-align: center;
    padding: 0 2em;
    position: relative;
    z-index: 9;

    // make element unselectable
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include mobile-portrait {
      font-size: 20px;
      padding: 0 1em;
      height: 90px;
    }

    &.active {
      background-color: $blue !important;

      &::after {
        background-color: $blue !important;
      }
    }

    &:hover {
      cursor: pointer;

      &::after {
        opacity: 0.7;
      }
    }

    &:active {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: "";
      width: 100%;
      left: 0;
      top: 0;
      background-color: #7d8298;
      opacity: 0.5;
      height: 100%;
      position: absolute;
      border-radius: 10px;
      backdrop-filter: blur(3px);
      z-index: -1;
      transition: all 0.3s;
    }
  }
}
