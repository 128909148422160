.navigation-button {
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  color: transparent;
  width: 145px;
  height: 145px;
  transition: opacity 0.3s ease-in;
  opacity: 0.6;
  padding: 0;

  @include mobile-portrait {
    width: 100px;
    height: 100px;
    background-size: contain;
  }

  &--previous {
    transform: scaleX(-1);
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
