.submissions-wrapper {

  position: relative;

  .table-header {
    position: absolute;
    top: 250px;
    min-height: 50px;
    width: 55.7%;
    border-radius: 4px 4px 0 0;
    margin: 0.8em auto;
    background-color: $blue;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 3em;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

    @include mobile-portrait {
      top: 210px;
      width: 85%;
      padding: 0 10px;
      font-size: 10px;
    }

    &__attribute {
      font-size: 1.2em;
      font-weight: bolder;
      font-family: "Lato", sans-serif;
      color: white;
      width: 70%;

      &--index {
        width: 7% !important;
      }

      &--email {
        width: 43% !important;
        text-align: center;
      }

      &--score {
        width: 10% !important;
        text-align: center;
      }

      &--attempt-time {
        width: 15% !important;
        text-align: center;
      }

      &--submitted-at {
        width: 25% !important;
        text-align: center;
      }
    }
  }

}
