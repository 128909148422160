.headline-container {
  min-width: 525px;
  width: 40%;
  text-shadow: 2px 3px 4px #000000;

  @include mobile-portrait {
    min-width: 100%;
  }

  .headline-header {
    margin: 0 0 35px;
    font-size: 100px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    color: white;

    @include mobile-portrait {
      font-size: 50px;
      text-align: center;
    }
  }

  .headline-paragraph-container {
    font-size: 35px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    color: white;

    @include mobile-portrait {
      font-size: 20px;
      padding: 0 2em;
      text-align: center;
    }

    & > p {
      margin: 10px 0;
    }
  }

  .cyan-text {
    color: $cyan;
  }
}
