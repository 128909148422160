
  .landing-container {
    padding: 160px 90px 0;

    @include mobile-portrait {
      padding: 100px 30px 0;
    }

    .row {
      display: flex;
      justify-content: space-around;

      @include mobile-portrait {
        flex-direction: column;
      }
    }

    h2 {
      font-size: 3em;
      margin: 2em 0 0 0;
      color: white;
      text-shadow: $text-shadow;
      font-family: "Lato", sans-serif;

      @include mobile-portrait {
        font-size: 30px;
        margin: 0 auto;
        padding: 2em 0;
        text-align: center;
      }

      span {
        color: $cyan;
      }
    }

    .landing-image {
      display: inline-block;
      width: 50%;
      min-width: 40%;

      @include mobile-portrait {
        width: 80%;
        margin: 1em auto 0 auto;
      }
    }
  }
