.new-item-section {
  position: absolute;
  top: 54px;
  left: 15vw;
  padding: 1em;
  background-color: $opaque-bg;
  border: 3px solid white;
  border-radius: 0px 0px 10px 10px;
  width: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile-portrait {
    left: 5vw;
    width: 20vw;
    padding: 0;
  }

  &--admin {
    @include mobile-portrait {
      right: -2px !important;
      left: auto;
      width: 70vw;
      top: 105px;
      padding: 0.3em;
      border: 2px solid white;
      border-radius: 10px 0px 0px 10px;
    }
  }

  input {
    font-size: 1.5em;
    min-width: 20vw;
    margin: 0 2px 0 0;
  }

  a {
    font-size: 1.4em;
    text-decoration: none;
    background-color: #357E9D;
    color: white;
    transition: 0.3s all;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    width: 50%;
    text-align: center;
    border-radius: 4px;
    padding: 6px 0;
    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }

    @include mobile-portrait {
      width: 100%;
    }
  }

  button {
    padding: 0.2em 0.5em;
    font-size: 1.4em;
    border: none;
    border-radius: 0 4px 4px 0;
    background-color: $blue;
    color: white;
    height: 35px;
    transition: 0.3s all;

    &:disabled {
      background-color: rgb(255, 57, 57);

      &:hover {
        filter: brightness(1);
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
  }
}
