.faq-wrapper {
  background-color: $opaque-bg;
  width: 70vw;
  min-height: 79.5vh;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  margin: 17vh 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile-portrait {
    width: 80vw;
  }

  &__entries-container {
    margin: 0 2em;

    @include mobile-portrait {
      margin: 0 2em;
    }

    &__content,
    &__answer {
      font-family: "Lato", sans-serif;
      font-size: 35px;
      color: white;
      text-shadow: $text-shadow;

      @include mobile-portrait {
        font-size: 20px;
      }
    }

    &__content {
      margin: 1em 0 0.3em 0;
    }

    &__answer {
      margin: 0.3em 0 1em 0;
    }
  }

  &__image {
    display: block;
    margin: 0 auto 2em;
    width: 20%;

    @include mobile-portrait {
      width: 70%;
    }
  }
}
