

.slide-wrapper {
  background-color: $opaque-bg;
  width: 70vw;
  min-height: 79.5vh;
  border-radius: 10px;
  backdrop-filter: blur(3px);
  margin: 17vh 0 0 0;

  @include mobile-portrait {
    width: 94vw;
    min-height: 67vh !important;
  }

  &__image {
    display: block;
    margin: 3.5em auto 0;
    width: 40%;
    height: 49vh;

    @include mobile-portrait {
      width: 70%;
      margin: 1em auto 0;
      height: 30vh;
    }
  }

  &__content{
    width: 88%;
    border-radius: 10px;
    margin: 0.4em auto 0;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    text-shadow: $text-shadow;
    font-size: 35px;
    font-family: "Lato", sans-serif;
    text-align: center;
    padding: 0 2em;
    position: relative;
    z-index: 9;

    @include mobile-portrait {
      font-size: 20px;
      height: 140px;
      margin: 1.5em auto;
      width: 80% !important;
      padding: 0 !important;
      padding: 0 0.5em;
    }

    &::after {
      content: '';
      width: 100%;
      left: 0;
      top: 0;
      background-color: #7d8298;
      opacity: 0.9;
      height: 100%;
      position: absolute;
      border-radius: 10px;
      backdrop-filter: blur(3px);
      z-index: -1;
    }

    &--header {
      text-transform: uppercase;
      font-size: 90px;
      font-family: 'Bebas Neue', cursive;
      width: 43.5vw;
      padding: 0;

      @include mobile-portrait {
        font-size: 30px;
      }
    }
  }
}

@keyframes fade {
  from {background-color:rgba(78, 96, 121, 0);}
  to {background-color:rgba(78, 96, 121, 0.1);}
}
