.login {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;

  h1 {
    font-size: 2.5em;
    width: 60%;
    text-align: center;

    @include mobile-portrait {
      font-size: 2em;
    }
  }

  h3 {
    color: red;
    font-size: 1.5em;
    width: 60%;
    text-align: center;
  }

  h3, h2, h1 {
    font-family: "Lato", sans-serif;
    font-weight: 600;
    text-shadow: $text-shadow;

    @include mobile-portrait {
      margin: 2em 0;
      font-size: 1em;
    }
  }

  h1 {
    @include mobile-portrait {
      font-size: 2em;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    background-color: $opaque-bg;
    backdrop-filter: blur(3px);
    border-radius: 10px;
    width: 30vw;
    height: 40vh;
    justify-content: center;
    padding: 0 5em;

    @include mobile-portrait {
      padding: 0 1em;
      width: 74vw;
      height: 60vh;
    }

    label {
      display: flex;
      flex-direction: column;
      font-size: 2em;
      font-family: "Lato", sans-serif;
      margin: 0 0 1em 0;
      font-weight: 600;
      text-shadow: $text-shadow;

      @include mobile-portrait {
        font-size: 1em;
      }

      input {
        padding: 15px;
        border-radius: 10px;
        border: none;
        font-size: 0.5em;
        margin: 0.5em 0 0 0;

        @include mobile-portrait {
          font-size: 1em;
        }
      }
    }

    button {
      background-color: $blue;
      border: none;
      font-size: 1.1em;
      padding: 0.5em 0;
      font-weight: 600;
      text-transform: uppercase;
      opacity: 0.7;
      transition: all 0.3s;
      color: white;
      letter-spacing: 1px;
      border-radius: 10px;
      width: 50%;
      margin: 0 auto;

      @include mobile-portrait {
        margin: 2em auto 0;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.9;
        box-shadow: 0 0 10px -2px black;
      }

      &:active {
        opacity: 1;
      }
    }
  }
}
