.admin-dashboard {
  min-height: 100vh;
  flex-direction: column;

  &__row {
    display: flex;

  }

  h2 {
    font-size: 2.5em;
    font-family: "Lato", sans-serif;
    color: white;

    @include mobile-portrait {
      font-size: 30px;
      text-align: center;
    }
  }
}
