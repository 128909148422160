.submission-container {
  background-color: $opaque-bg;
  width: 70vw;
  min-height: 79.5vh;
  border-radius: 10px;
  backdrop-filter: blur(3px);
  margin: 17vh 0 0 0;

  @include mobile-portrait {
    width: 94vw;
    min-height: 76vh;

    .slide-wrapper__content {
      margin: 0.5em auto;
    }
  }

  &__image {
    display: block;
    margin: 3.5em auto;
    width: 40%;
    height: 32vh;

    @include mobile-portrait {
      margin: 0 auto;
      height: 25vh;
    }
  }
}
