html, body, #root {
  height: 100% !important;
  min-height: 100vh;
}

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.centered-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-wrapper {
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
}

.navigation {
  position: absolute;
  bottom: 1%;
  width: 95vw;
  left: 0;
  right: 0;
  margin: 0 auto;

  button {
    position: absolute;
    bottom: 0;
  }

  &-button--previous {
    left: 0;
  }

  &-button--next {
    right: 0;
  }
}
