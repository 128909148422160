.logged-user-id-container {
  position: absolute;
  top: 60px;
  right: 15vw;
  text-align: center;
  background-color: $opaque-bg;
  backdrop-filter: blur(3px);
  width: 350px;
  height: 45px;
  border-radius: 0 0 10px 10px;
  color: white;
  text-transform: uppercase;
  text-shadow: $text-shadow;
  font-family: "Bebas Neue", cursive;
  font-size: 25px;
  font-weight: lighter;
  letter-spacing: 1px;
  z-index: 99;
  padding: 6px 0 0 0;

  @include mobile-portrait {
    right: 3vw;
    font-size: 20px;
    width: 250px;
    height: 40px;
  }
}
